import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-3011/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-3011",
  "path": "/Indoor_Cameras/IN-3011/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-3011 Indoor IP Camera",
  "image": "../P_SearchThumb_IN-3011HD.png",
  "social": "/images/Search/P_SearchThumb_IN-3011HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-3011HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-3011' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-3011 Indoor IP Camera' image='/images/Search/P_SearchThumb_IN-3011HD.png' twitter='/images/Search/P_SearchThumb_IN-3011HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-3011/' locationFR='/fr/Indoor_Cameras/IN-3011/' crumbLabel="IN-3011" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-3011",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-3011",
        "aria-label": "in 3011 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-3011`}</h1>
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/949cc421527a0ea1cf44ed95667f5679/573d3/IN-3011_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACLElEQVQoz32S60uTYRjG9z+VJzBTY07Qgaarnd3hnctj5haWtrRaam5hLPowKDyE2jxMU3RUQhBZtgoNiepDms0+qNkHMwXd/MVefbcg8ILn4X7g5sd93dcji8ZixLW6vk5rWzs11TUER8eQtL+/L55oNJp4x7WysoLX48V1xUU4HE70y+JX+N171Fo9tbUOLjov43Q24L7ZysbGL7EpFouJQAk2MjxCSdFpcjJPkpGSTlZmFh23Otje3j4ABoaG0RuMtLXfpsnlprLqPAWFhXz6/EUExGF7e3tivbOzQ7PrGkZtGcODQZqb3WjVOs6qNHxdXDoAzryeJTv3FAVKJVq9AXmeAkOZibX1n4kJJWC89nXeRaPS4KhzUi7YESwCVRXVrK6tIZNsCOV20tIzOJ6SQp4in76BR4nppB1KvZHID+y2ClKPpZGemkFpyRmmpp4kdzg3N09Obi5mswWNWo3ZaqV/MCjFkthh7DDA6WfT5JzIxma1odPoUJWq8PvvJ4F1F+pRKpXMvHwlpmwVBHz3/CwuRw6nTFre3PzNjetuLCYLdkHApDOiU+sxGkwsLHxEtrX1B4Uin+KiYkaDj9Fq9cjlcmznKpl+/iJhe3d3V6zfzL7F4biEs6GRO50e6ttaqGxq5KqrhYGBALJ481QoRFd3Nz09vXi8Xnw+Hz29D1lc+vZfKN+XI/T1B5gMPWVsPETXxCgPhgJMjE8yP/fhwPJR+vdjS6Ecpb/hhXVzTCnDbAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/949cc421527a0ea1cf44ed95667f5679/e4706/IN-3011_Overview_width1650.avif 230w", "/en/static/949cc421527a0ea1cf44ed95667f5679/d1af7/IN-3011_Overview_width1650.avif 460w", "/en/static/949cc421527a0ea1cf44ed95667f5679/7f308/IN-3011_Overview_width1650.avif 920w", "/en/static/949cc421527a0ea1cf44ed95667f5679/e1c99/IN-3011_Overview_width1650.avif 1380w", "/en/static/949cc421527a0ea1cf44ed95667f5679/e02f7/IN-3011_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/949cc421527a0ea1cf44ed95667f5679/a0b58/IN-3011_Overview_width1650.webp 230w", "/en/static/949cc421527a0ea1cf44ed95667f5679/bc10c/IN-3011_Overview_width1650.webp 460w", "/en/static/949cc421527a0ea1cf44ed95667f5679/966d8/IN-3011_Overview_width1650.webp 920w", "/en/static/949cc421527a0ea1cf44ed95667f5679/445df/IN-3011_Overview_width1650.webp 1380w", "/en/static/949cc421527a0ea1cf44ed95667f5679/44758/IN-3011_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/949cc421527a0ea1cf44ed95667f5679/81c8e/IN-3011_Overview_width1650.png 230w", "/en/static/949cc421527a0ea1cf44ed95667f5679/08a84/IN-3011_Overview_width1650.png 460w", "/en/static/949cc421527a0ea1cf44ed95667f5679/c0255/IN-3011_Overview_width1650.png 920w", "/en/static/949cc421527a0ea1cf44ed95667f5679/b1001/IN-3011_Overview_width1650.png 1380w", "/en/static/949cc421527a0ea1cf44ed95667f5679/573d3/IN-3011_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/949cc421527a0ea1cf44ed95667f5679/c0255/IN-3011_Overview_width1650.png",
              "alt": "INSTAR IN-3011",
              "title": "INSTAR IN-3011",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-3011 is an all-round solution for indoor surveillance. The camera head is remotely controllable and equipped with infrared diodes to utilize night vision in pitch dark environments. It features integrated speakers and a microphone which makes the perfect camera for monitoring your baby or toddler. Any device (i.e. smartphones) can connect to the cameras web interface and allows you to control the pan & tilt feature. With 280 degrees horizontally and 120 degrees vertically the pan and tilt feature basically covers the whole area in front of the camera. Thanks to its compact size and its modern design, you won't be just limited to use it as a surveillance camera but also for your personal projects. The `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Alarm_FTP_Upload/"
      }}>{`FTP support`}</a>{` allows you to use the IN-3011 as a multi-functional webcam for `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Website_Integration/"
      }}>{`integration on your website`}</a>{`. The difference between the IN-3010 and the IN-3011 is that this camera allows you to manually switch off the infrared lights. This enables you to see through windows at night without being troubled by reflections from the IR LEDs.`}</p>
    <Link to="/Indoor_Cameras/IN-3011/Technical_Specifications/" mdxType="Link"><EuiButton fill color="primary" mdxType="EuiButton">Technical Specifications</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Remote controlled Pan & Tilt - 288° pan and 120° tilt`}</li>
      <li parentName="ul">{`Alarm I/O for external sensors (e.g. motion or smoke detectors) and receivers (e.g. house automations)`}</li>
      <li parentName="ul">{`2-Way audio via an integrated `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/" mdxType="Link">{`Microphone & Speaker`}</Link></li>
      <li parentName="ul">{`Detachable wall mount with a standard 1/4-inch thread (compatible with regular camera tripods, e.g. GorillaPods)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">{`Integrated personal, free `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS (`}{`"`}{`Internet`}{`"`}{`) Address`}</Link>{` `}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">{`Web user interface allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)`}</li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-3011 is the perfect camera for indoor surveillance inside your office or house - light, compact and versatile with a camera module that is less than 10cm in height. It is designed to be used as a tabletop camera but can be attached to walls or ceilings by its stand. The camera can easily be controlled through your smartphone from inside your network and also over the internet - via the free-of-charge integrated DDNS service. Thanks to the integrated microphone the camera can also be used for audio surveillance - transforming this model into the baby monitor of your choice. The IN-3011 can easily be connected to your network by using its 54MBit (802.11b/g) WiFi module (WPA/WPA2) or through the 100MBit network interface to ensure a fluid video stream. The camera can also see during the night thanks to 8 infrared LEDs operating at a wavelength of 850nm. The max. indoor range for the cameras night vision is around 5-8 meters. If an alarm is triggered the camera can send an email to notify you. Each email also has 4-6 pictures attached. This way it will be very easy to check quickly what caused the alert to make sure your home is safe.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      